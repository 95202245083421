import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Footer from "components/footers/FiveColumnDark.js";


import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>Leckere & Preiswerte <HighlightedText>Mahlzeiten in Ihrer Nähe.</HighlightedText></>}
        description="Willkommen im Irena Bäckerei & Grill, wo kulinarische Köstlichkeiten auf herzliche Gastfreundschaft treffen."
        imageSrc="https://irenabackerei.com/img/irenainside.jpeg"
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Über uns"
        watchVideoButtonText="Über uns video"
      />
      <MainFeature
        subheading={<Subheading>Wir sind in der Lüpertzender Str. 117</Subheading>}
        heading={
          <>
            Wir tun dies seit 
            <wbr /> <HighlightedText>über 25 Jahren.</HighlightedText>
          </>
        }
        description={
          <Description>
            Im Irena Bäckerei & Grill verbinden wir die Tradition des Balkans mit Innovation und bieten eine vielfältige Speisekarte, die jeden Gaumen anspricht. Von frisch gebackenem Brot und Gebäck bis hin zu köstlichen gegrillten Spezialitäten – jedes Gericht wird mit Liebe und Aufmerksamkeit zum Detail zubereitet.
            <br />
            <br />
            Kommen Sie vorbei und genießen Sie die Essenz hausgemachter Köstlichkeiten, wo jeder Besuch sich wie nach Hause kommen anfühlt.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Neueste Angebote"
        primaryButtonUrl="Neueste Angebote"
        imageSrc={
          "https://images.unsplash.com/photo-1460306855393-0410f61241c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Ein blick in die <HighlightedText>Speisekarte.</HighlightedText>
          </>
        }
      />
      <Features
        heading={
          <>
            Erstaunliche <HighlightedText>Dienstleistungen.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "Lüpertzender Str. 117 41061 Mönchengladbach, Deutschland",
          },
          {
            imageSrc: chefIconImageSrc,
            title: "Professionelle Köche",
          },
          {
            imageSrc: celebrationIconImageSrc,
            title: "Geburtstags-Catering",
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      <MainFeature2
        subheading={<Subheading>Eine renommierte Marke</Subheading>}
        heading={<>Warum <HighlightedText>uns wählen?</HighlightedText></>}
        description="Auf den Regalen steht eine Auswahl an frisch gebackenem Brot, von knusprigen Baguettes bis hin zu lockeren Sauerteigbroten. Vitrinen präsentieren eine Auswahl an Gebäck und Desserts, eines verlockender als das andere."
        primaryButtonText="Mehr lesen"
        primaryButtonUrl="AboutUsPage"
        imageInsideDiv={false}
        imageSrc="https://irenabackerei.com/img/irenainside2.jpeg"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      {/* <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      />
       <DownloadApp
        text={<>People around you are ordering delicious meals using the <HighlightedTextInverse>Treact App.</HighlightedTextInverse></>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
}
