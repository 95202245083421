import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnDark";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";


const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Über Irena</Subheading>}
        heading="Willkommen im Irena Bäckerei & Grill"
        description="Unsere Leidenschaft für hochwertige Zutaten und authentische Aromen strahlt in jedem Bissen durch und garantiert ein unvergessliches kulinarisches Erlebnis für unsere Gäste. Im Irena Bäckerei & Grill verbinden wir die Tradition des Balkans mit Innovation und bieten eine vielfältige Speisekarte, die jeden Gaumen anspricht. Von frisch gebackenem Brot und Gebäck bis hin zu köstlichen gegrillten Spezialitäten – jedes Gericht wird mit Liebe und Aufmerksamkeit zum Detail zubereitet. Kommen Sie vorbei und genießen Sie die Essenz hausgemachter Köstlichkeiten, wo jeder Besuch sich wie nach Hause kommen anfühlt."
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Unsere Vision</Subheading>}
        heading="Die Bäckerei selbst ist eine Symphonie aus Anblicken und Gerüchen"
        description="Auf den Regalen steht eine Auswahl an frisch gebackenem Brot, von knusprigen Baguettes bis hin zu lockeren Sauerteigbroten. Vitrinen präsentieren eine Auswahl an Gebäck und Desserts, eines verlockender als das andere."
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Unsere Werte</Subheading>}
        heading="Wir befolgen diese."
        description="Wir begrüßen jeden Gast wie eine Familie und sorgen dafür, dass jeder Besuch zu einem unvergesslichen Erlebnis voller Süße und Freude wird."
        cards={[
          {
            imageSrc: CustomerLoveIconImage,
            title: "Frische",
            description: "Wir begrüßen jeden Gast wie eine Familie und sorgen dafür, dass jeder Besuch zu einem unvergesslichen Erlebnis voller Süße und Freude wird."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Gesundheit",
            description: "Wir begrüßen jeden Gast wie eine Familie und sorgen dafür, dass jeder Besuch zu einem unvergesslichen Erlebnis voller Süße und Freude wird."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Kundenzufriedenheit",
            description: "Wir begrüßen jeden Gast wie eine Familie und sorgen dafür, dass jeder Besuch zu einem unvergesslichen Erlebnis voller Süße und Freude wird."
          },
        ]}
        linkText=""
      />
      
      <Footer />
    </AnimationRevealPage>
  );
};
